/* eslint-disable no-console */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  DashboardLayout,
  OverlayDashboard,
  DownloadButtonWrapper,
} from "./styles";
import Waiting from "../Waiting";
import {
  setSelectedYear,
  toggleDummyChallenges,
  fetchChallengesDashBoardAPI,
  fetchAdminActiveandUpcomingChallenges
} from "../../redux/actions";
import Overview from "./overview";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import CompletedChallengeV2 from "./completedChallenge";
import { getAdminCompletedChallenges } from "../../redux/actions";
import YearDropdown from "../common/YearDropdown/YearDropdown";
import { TabContainer, TabView, IconWrapper } from "../ChallengeDetailsV2/styles";
import { Container } from "../ChallengeDashboardV2/CompletedChallenge/styles";
import { LaunchchallengeButton,  ChallengeCardWrapper } from "./styles";
import HoverButton from '../common/HoverButtonV2';
import NewSingleChallenge from '../ChallengeDashboardV2/DashboardCards/NewSingleChallenge';
import { withTranslation } from "react-i18next";

class CompanyChallenges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: props.companyInfo.id,
      selectedTab: 2,
      menuIsOpen: false,
      onHover:null,
      showAllChallenge: false,
      selectTabValue: null
    };
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    const { fetchChallengesDashBoardAPI, fetchAdminActiveandUpcomingChallenges } = this.props;
    const { companyId } = this.state;
    let currentYear = new Date().getFullYear();
    fetchChallengesDashBoardAPI(companyId, currentYear);
    fetchAdminActiveandUpcomingChallenges(companyId);
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(event.target)
    ) {
      this.setState({ menuIsOpen: false });
    }
  };

  toggleMenu = () => {
    this.setState((prevState) => ({
      menuIsOpen: !prevState.menuIsOpen,
    }));
  };

  updateDropdown = (year) => {
    const {
      setSelectedYear,
      fetchChallengesDashBoardAPI,
      getAdminCompletedChallenges,
      selectedTabId,
    } = this.props;
    const { selectedTab, companyId } = this.state;
    setSelectedYear(year);
    selectedTabId === 1 ? fetchChallengesDashBoardAPI(companyId, year) : null;
    selectedTab === 2 ? getAdminCompletedChallenges(year, companyId) : null;
  };

  redirection = (path) => {
    const { history } = this.props;
    history.push(`/${path}`);
  };

  showPastEvents = (path) => {
    this.props.history.push(path);
  };

  onEnterMouse = (id) => {
    this.setState({
      onHover: id
    })
  };

  onLeaveMouse = () => {
    this.setState({
      onHover: -1
    })
  };

  hideShowChallenge = () => {
    this.setState((prevState) => ({
      showAllChallenge: !prevState.showAllChallenge
    }));
  };

  schedule = () =>(
    <svg
      width="20px"
      height="auto"
      viewBox="-5 0 25 25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="ui-gambling-website-lined-icnos-casinoshunter"
          transform="translate(-1913.000000, -158.000000)"
          fill="#0D4270"
          fillRule="nonzero"
        >
          <g id="1" transform="translate(1350.000000, 120.000000)">
            <path
              d="M566.453517,38.569249 L577.302459,48.9938158 L577.39261,49.0748802 C577.75534,49.423454 577.968159,49.8870461 578,50.4382227 L577.998135,50.6228229 C577.968159,51.1129539 577.75534,51.576546 577.333675,51.9774469 L577.339095,51.9689832 L566.453517,62.430751 C565.663694,63.1897497 564.399001,63.1897497 563.609178,62.430751 C562.796941,61.650213 562.796941,60.3675924 563.609432,59.5868106 L573.012324,50.5572471 L563.609178,41.4129456 C562.796941,40.6324076 562.796941,39.349787 563.609178,38.569249 C564.399001,37.8102503 565.663694,37.8102503 566.453517,38.569249 Z"
              id="left"
              transform="translate(570.500000, 50.500000) scale(-1, 1) translate(-570.500000, -50.500000) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  ) 

  headerView = () =>
    (
      <TabContainer marginBottom="25px" paddingRight="15px">
        <IconWrapper margin="10px auto 10px 10px" marginLeft>
          <HoverButton
            title={this.props.t("Back")}
            width="20px"
            height="24px"
            svgPath={this.schedule()} 
            onClick={() => this.hideShowChallenge() }
            backgroundColor={"#005C870D"}
            backgroundColorActive={"#005C87"}
          />
          <div className="title">{this.props.t("Active Challenges")}</div>
        </IconWrapper>
      </TabContainer>
    )

  renderChallenges = () => {
    const { history, toggleDummyChallenges, adminChallengeActive } = this.props;
    return (
      <ChallengeCardWrapper margin="25px 0 0 0">
        {this.headerView()}
        <div className="siglneCardmain"> 
          {
            adminChallengeActive.map((challenge, index) =>
              <NewSingleChallenge onEnterMouse={this.onEnterMouse} onLeaveMouse={this.onLeaveMouse} onHover={this.state.onHover} challenge={challenge} history={history} key={index} toggleDummyChallenges={toggleDummyChallenges} margin={index} adminChallenge={1}/>
            )
          }
          {<div style={{width:"399px", height:"334px", border: "3px dashed #005C874D",borderRadius:"6px", display:"flex", alignItems:'center', justifyContent:"center"}}>
            <LaunchchallengeButton width="240px">
              <div onClick={() => { this.redirection('company/challenges/categories') }} >
                + {this.props.t("Launch A Challenge")}
              </div>
            </LaunchchallengeButton>
          </div>}
        </div>
      </ChallengeCardWrapper>
    )
  };

  render() {
    const {
      networkError,
      employeeCount,
      history,
      yearList,
      selectedYearInfo,
      challengeTopLeadersData,
      challengeTopParticpantsData,
      challengeOverviewData,
      behaviorChallengeData,
      activityChallengeData,
      user,
      topChallenges,
      challengesData,
      t,
      selectedTabId,
      adminChallengeOverviewData,
      adminChallengeActive
    } = this.props;
    const { companyId, showAllChallenge } = this.state;
    if (
      networkError ||
      isNull(challengeTopLeadersData) ||
      isNull(challengeTopParticpantsData) ||
      isNull(adminChallengeOverviewData) ||
      isNull(employeeCount) ||
      isUndefined(challengeTopLeadersData) ||
      isUndefined(challengeTopParticpantsData) ||
      isUndefined(adminChallengeOverviewData) ||
      isUndefined(behaviorChallengeData) ||
      isUndefined(activityChallengeData) ||
      isNull(activityChallengeData) ||
      isNull(behaviorChallengeData) ||
      isNull(user) ||
      isUndefined(user) ||
      isUndefined(topChallenges)
    ) {
      return <Waiting />;
    }
    return (
      <Container>
        <div className="mainContainer">
          <OverlayDashboard>
            {!showAllChallenge?<DashboardLayout>
              <TabContainer marginBottom="25px">
                <TabView
                  width
                  padding
                  active={selectedTabId === 1}
                  onClick={() => this.showPastEvents("/company/challenges")}
                >
                  {this.props.t("Overview")}
                </TabView>
                {/* <TabView active={selectedTabId === 4} onClick={()=>this.setState({selectTabValue: 4})}>
                  Analytics
                </TabView> */}
                <TabView
                  width
                  padding
                  active={selectedTabId === 2}
                  onClick={() =>
                    this.showPastEvents("/company/challenges/completed")
                  }
                >
                  {" "}
                  {this.props.t("Completed")}
                </TabView>
                <TabView
                  width
                  padding
                  active={selectedTabId === 3}
                  onClick={() =>
                    this.showPastEvents("/company/challenges/categories")
                  }
                >
                  {" "}
                  {this.props.t("Library")}
                </TabView>
                <DownloadButtonWrapper>
                  <YearDropdown
                    refOutside={this.dropdownRef}
                    toggleMenu={this.toggleMenu}
                    // title={selectedYearInfo && <div className="title">{selectedYearInfo}</div>}
                    title={
                      selectedYearInfo && (
                        <div
                          className="title"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            fontFamily:"Rubik-Medium",
                            color: "#005c87",
                            fontSize:"16px",
                            lineHeight:"20px"
                            // padding: "4px 0px"
                          }}
                        >
                          <div>{selectedYearInfo}</div>{" "}
                          <div style={{ display:"flex", alignItems:"center", width:this.state.menuIsOpen?"16px":"auto"}}>
                            {/* <svg
                              width="12"
                              height="16"
                              viewBox="0 0 12 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                // position: 'relative', top: '9px', right: '35px',
                                transform: this.state.menuIsOpen
                                  ? "rotate(90deg)"
                                  : "none",
                              }}
                            >
                              <path
                                d="M12 10C12 10.3585 11.8559 10.7169 11.5684 10.9901L2.51663 19.5897C1.94083 20.1368 1.00725 20.1368 0.431681 19.5897C-0.143894 19.0429 -0.143894 18.1561 0.431681 17.609L8.44122 10L0.43196 2.39098C-0.143614 1.84394 -0.143614 0.957264 0.43196 0.410484C1.00753 -0.136826 1.94111 -0.136826 2.51691 0.410484L11.5687 9.00992C11.8563 9.28333 12 9.64172 12 10Z"
                                fill="#0D4270"
                              />
                            </svg> */}
                            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                              style={{transform: this.state.menuIsOpen? "rotate(90deg)": "none"}}
                            >
                              <path d="M10 8.00003C10 8.28678 9.87995 8.5735 9.64036 8.79212L2.09719 15.6718C1.61736 16.1094 0.839379 16.1094 0.359734 15.6718C-0.119911 15.2343 -0.119911 14.5249 0.359734 14.0872L7.03435 8.00003L0.359967 1.91279C-0.119678 1.47515 -0.119678 0.765811 0.359967 0.328387C0.839612 -0.109461 1.61759 -0.109461 2.09743 0.328387L9.6406 7.20793C9.88023 7.42666 10 7.71338 10 8.00003Z" fill="#005C87"/>
                            </svg>
                          </div>
                        </div>
                      )
                    }
                    // labelStyle={{margin:"11px 10px 0px 0px"}}
                    data={yearList}
                    onSelect={this.updateDropdown}
                    style={{
                      background: "#F7F5EB80",
                      lineHeight: "20px",
                      color: "rgba(0, 92, 135, 0.6)",
                      padding: "0px",
                      border:"1px solid #005C874D"
                    }}
                  />
                </DownloadButtonWrapper>
              </TabContainer>
              {selectedTabId === 1 && (
                <Overview
                  history={history}
                  toggleDummyChallenges={toggleDummyChallenges}
                  challengeTopLeadersData={challengeTopLeadersData}
                  challengeTopParticpantsData={challengeTopParticpantsData}
                  challengeOverviewData={challengeOverviewData}
                  employeeCount={employeeCount || 0}
                  behaviorChallengeData={behaviorChallengeData}
                  activityChallengeData={activityChallengeData}
                  user={user}
                  topChallenges={topChallenges}
                  adminChallengeOverviewData={adminChallengeOverviewData}
                  adminChallengeActive={adminChallengeActive}
                  hideShowChallenge={this.hideShowChallenge}
                />
              )}
              {selectedTabId === 2 && (
                <CompletedChallengeV2
                  history={history}
                  challengesData={challengesData}
                  t={t}
                  yearList={yearList}
                  selectedYearInfo={selectedYearInfo}
                  companyId={companyId}
                />
              )}
            </DashboardLayout>:this.renderChallenges()}
          </OverlayDashboard>
        </div>
      </Container>
    );
  }
}

CompanyChallenges.defaultProps = {
  companyInfo: { id: 1, name: "" },
};
CompanyChallenges.propTypes = {
  networkError: PropTypes.string,
  companyInfo: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  employeeCount: PropTypes.number,
  history: PropTypes.object,
  selectedYearInfo: PropTypes.number,
  yearList: PropTypes.array,
  groupSummaryYearList: PropTypes.array,
  setSelectedYear: PropTypes.func,
  showCompaniesDropdown: PropTypes.bool,
  DownloadFileAccess: PropTypes.bool,
  companyBrandingCallBack: PropTypes.func,
  challengeOverviewData: PropTypes.array,
  challengeTopLeadersData: PropTypes.array,
  challengeTopParticpantsData: PropTypes.array,
  toggleDummyChallenges: PropTypes.func,
  fetchChallengesDashBoardAPI: PropTypes.func,
  activityChallengeData: PropTypes.array,
  behaviorChallengeData: PropTypes.array,
  user: PropTypes.object,
  topChallenges: PropTypes.array,
  challengesData: PropTypes.array,
  t: PropTypes.func,
  getAdminCompletedChallenges: PropTypes.func,
  defaultActiveKey: PropTypes.number,
  selectedTabId: PropTypes.number,
  adminChallengeOverviewData: PropTypes.array,
  fetchAdminActiveandUpcomingChallenges: PropTypes.func,
  adminChallengeActive: PropTypes.array
};

const mapStateToProps = (state) => ({
  networkError: state.companyDashboard.networkError,
  employeeCount: state.companyDashboard.employeeCount,
  selectedYearInfo: state.companyDashboard.selectedYearInfo,
  challengeOverviewData: state.challengesAdmin.challengeOverviewData,
  challengeTopLeadersData: state.challengesAdmin.challengeTopLeadersData,
  challengeTopParticpantsData:
    state.challengesAdmin.challengeTopParticpantsData,
  activityChallengeData: state.challengesAdmin.activityChallengeData,
  behaviorChallengeData: state.challengesAdmin.behaviorChallengeData,
  user: state.profileData.user,
  topChallenges: state.companyDashboard.topChallenges,
  adminChallengeOverviewData: state.challengesAdmin.adminChallengeOverviewData,
  adminChallengeActive: state.challengesAdmin.adminChallengeActive
});

const mapDispatchToProps = (dispatch) => ({
  fetchChallengesDashBoardAPI: (id, year) =>
    dispatch(fetchChallengesDashBoardAPI(id, year)),
  setSelectedYear: (year) => dispatch(setSelectedYear(year)),
  toggleDummyChallenges: (bool) => dispatch(toggleDummyChallenges(bool)),
  getAdminCompletedChallenges: (year, companyId) =>
    dispatch(getAdminCompletedChallenges(year, companyId)),
  fetchAdminActiveandUpcomingChallenges: (companyId) =>
    dispatch(fetchAdminActiveandUpcomingChallenges(companyId))
});

export default connect(mapStateToProps, mapDispatchToProps)((withTranslation())(CompanyChallenges));
