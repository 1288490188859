import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Container, CardWrapper, ChallengeCard, ImageCard, BottomCard, NoData, ChallangeTypeCard, Completed } from '../ChallengeDashboardV2/CompletedChallenge/styles';
import _ from 'lodash';
import Waiting from '../Waiting/index';
import { ImageUrl } from "../../utils/constants";
import moment from "moment";
import { convertDateInTimezone } from "../../utils/methods";
import { connect } from 'react-redux';
import { setSelectedYear } from '../../redux/actions/companyDashboard/index';
import {getAdminCompletedChallenges} from '../../redux/actions/challengeActions/index'
import { withTranslation } from 'react-i18next';
import LazyImage from '../common/LazyImage/LazyImage';

class CompletedChallengeV2 extends Component {

  componentDidMount() {
    const { getAdminCompletedChallenges, selectedYearInfo, companyId } = this.props;
    getAdminCompletedChallenges(selectedYearInfo, companyId)
    // this.props.history.push(`/company/challenges/completed`)
  }

  challengeRedirect = (id) => {
    this.props.history.push(`/company/challenges/completed/${id}`)
  };

  updateDropdown = (year) => {
    const { setSelectedYear, getAdminCompletedChallenges, companyId } = this.props;
    setSelectedYear(year);
    getAdminCompletedChallenges(year, companyId);
  };


  render() {
    const { challengesData, t } = this.props;
    if (!challengesData || _.isNull(challengesData) || _.isUndefined(challengesData)) {
      return <Waiting />
    }
    let adminChallengesData = challengesData.filter((obj) => obj.is_user_challenge === 0)
    return (
      <Container pagePadding>
        <div className="mainContainer">
          {adminChallengesData && adminChallengesData.length > 0 ?
            <CardWrapper>
              {adminChallengesData && adminChallengesData.length > 0 && adminChallengesData.map((challenge, index) => (
                <ChallengeCard key={index} margin={(index + 1) % 3 === 0 ? "0px" : "25px"} onClick={() => this.challengeRedirect(challenge.id)}>
                  <ChallangeTypeCard>{challenge.challenge_type === 'survivor'?"Survivor":
                    challenge.challenge_type === 'group' && challenge.is_team_challenge !== 1 ? "Classic":
                      challenge.challenge_type === 'new_group'? "Group":
                        challenge.challenge_type === 'group' && challenge.is_team_challenge === 1?"Team":"Behavior"} Challenge</ChallangeTypeCard>
                  <ImageCard>
                    <LazyImage src={`${ImageUrl}/${challenge.image}`} alt="challenge" />
                  </ImageCard>
                  <BottomCard>
                    <div className="challenge-name">
                      <div className="name">{challenge.title}</div>
                      <div className="icon">
                        <img src={`${ImageUrl}/${challenge.event_icon}`} alt="event-type" />
                      </div>
                    </div>
                    <div className="date">
                      {moment(convertDateInTimezone(challenge.start_date)._d).format('DD MMM, YYYY')}- {moment(convertDateInTimezone(challenge.end_date)._d).format('DD MMM, YYYY')}
                    </div>
                    {/* <div className="participant-card">
                      {challenge.user_list && challenge.user_list.length > 0 && <div className="count">{challenge.user_list.length}+</div>}
                      <div className="participant">{t("Participants")}</div>
                      <div className="completed">{t("Completed!")}</div>
                    </div> */}
                    <Completed>You earned <b>{`+${challenge.challenge_point} Social Points`}</b> for Participating!</Completed>
                  </BottomCard>
                </ChallengeCard>))}
            </CardWrapper> : <NoData>{t("No Data Present")}</NoData>}
        </div>
      </Container>
    );
  }
}

CompletedChallengeV2.propTypes = {
  challengesData: PropTypes.array,
  history: PropTypes.object.isRequired,
  yearList: PropTypes.array,
  selectedYearInfo: PropTypes.number,
  setSelectedYear: PropTypes.func,
  getAdminCompletedChallenges: PropTypes.func,
  t: PropTypes.func,
  companyId: PropTypes.number
};

const mapStateToProps = (state) => ({
  activeTab: state.challenges.activeTab,
  isLoading: state.challenges.isLoading,
  challengesData: state.challenges.response,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedYear: (year) => dispatch(setSelectedYear(year)),
  getAdminCompletedChallenges: (year, companyId) => dispatch(getAdminCompletedChallenges(year, companyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CompletedChallengeV2));